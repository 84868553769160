import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageCollection = ({
  data: {
    reviews,
    wallets,
    walletReviews,
    comparisons,
    coupons,
    collectionInfo
  },
  ...props
}) => {
  let posts
  let isCoupon = false
  switch (collectionInfo.slug) {
    case '/category/reviews/':
      posts = reviews
      break
    case '/category/reviews-all/':
      posts = [reviews, ...walletReviews]
      break
    case '/category/wallets/':
      posts = wallets
      break
    case '/category/coupons/':
      posts = coupons
      isCoupon = true
      break
    case '/category/wallet-reviews/':
      posts = walletReviews
      break
    case '/category/guides/':
      posts = comparisons
      break
    default:
      posts = {
        totalCount: 0
      }
      break
  }

  return (
    <Layout {...props}>
      <Seo title={collectionInfo.name} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={collectionInfo.name}
          subheader={collectionInfo.title}
          running={collectionInfo.description}
          totalCount={posts.totalCount}
        />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
              omitCategory={
                props.pageContext &&
                props.pageContext.collectionType === 'category'
              }
              isCoupon={isCoupon}
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
    </Layout>
  )
}

export default PageCollection
