import { graphql } from 'gatsby'
import Collection from '../containers/Collection'

export default Collection

export const pageQuery = graphql`
  query allPostsByCategoryQuery($skip: Int!, $limit: Int!, $slug: String!) {
    collectionInfo: category(slug: { eq: $slug }) {
      id
      name
      slug
      description
    }

    wallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...WalletPreview
        ...WalletThumbnailRegular
        ...WalletLogo
      }
      ...WalletPagination
    },

    coupons: allCoupon(
      filter: {
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...CouponPreview
      }
      ...CouponPagination
    },

    reviews: allReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        category: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ReviewPreview
        ...ReviewThumbnailRegular
        ...ReviewLogo
      }
      ...ReviewPagination
    },

    walletReviews: allWalletReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        category: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...WalletReviewPreview
        ...WalletReviewThumbnailRegular
        ...WalletReviewLogo
      }
      ...WalletReviewPagination
    },
    
    comparisons: allComparison(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        category: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ComparisonPreview
        ...ComparisonThumbnailRegular
      }
      ...ComparisonPagination
    },
  }
`
